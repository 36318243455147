import { getIP } from "../hooks/env";
import { generateHeaders } from "../hooks/User";


const IP  = getIP();

function saveLocalReport(report, linkkey, proddate, report_type) {
    let key = `${linkkey} - ${proddate} - ${report_type}`;
        localStorage.setItem(key, JSON.stringify(report))
}

function getLocalReport(linkkey, proddate, report_type) {
    let key = `${linkkey} - ${proddate} - ${report_type}`;
    const item = localStorage.getItem(key);
    if (typeof item == 'undefined' || item == "undefined" ) {
        return null;
    }
    let report = JSON.parse(item);
    return report;
}

function deleteLocalReport(linkkey, proddate, report_type) {
    let key = `${linkkey} - ${proddate} - ${report_type}`;
    localStorage.removeItem(key);
}

async function resetAllFilters() {
    const req = await fetch(
        `${IP}/api/v1/reset_filters`,
        {
            headers: await generateHeaders()
        }
    )
    if (req.status == 200) {
        window.location.replace(`${IP}/`);
    }
}

async function clearData() {
    localStorage.clear();
}

export {
    saveLocalReport,
    getLocalReport,
    deleteLocalReport,
    resetAllFilters,
    clearData
}