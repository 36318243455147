import React from "react"
import uuid from "react-uuid";
import './select.css';
import { getAreas, getLeases, getFilters, getOperators, searchReports } from "../../hooks/Search";
import './styles-alternate.css';
import { mockDocuments, generateDocuments } from "../ReportNavigator/constants";
import { OperatorSelect, LeaseSelector } from "./Selectors";
import { sleep } from "../../utils/sleep";
import { resetAllFilters } from "../../utils/session";

const arrayRange = (start, stop, step) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
    );

const fechaActual = new Date();
const years = arrayRange(1999, fechaActual.getFullYear(), 1);
years.reverse();

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
    'November', 'December'
]

export default class ReportSearchAlternate extends React.Component {
    constructor(props) {
        super(props);
        this.parent = props.parent;
        this.loadingLeases = false;
        this.parent.setSearch(this);

        this.areaInput = React.createRef();
        this.state = {
            values: {
                productionDates: [],
                leases: []
            },
            parameters: {
                reportType: null
            },
            areas: null,
            leases: null,
            collapsed: false,
            customFilters: true,
            dateAsRange: false,
            loadingLeases: false,
            userData: props.userData
        }
    }

    addDate() {
        if (!this.state.parameters.month || !this.state.parameters.year) {
            return;
        }
        const values = this.state.values;
        let dates = [...this.state.values.productionDates];
        const date = this.state.parameters.year + '-' + (months.indexOf(this.state.parameters.month) + 1);
        if (dates.includes(date)) {
            return;
        }
        dates.push(date);
        values.productionDates = dates;

        this.setState((p)=>{
            return {
                values: values
            }            
        })
    }

    removeDate(index) {
        this.removeValue('productionDates', index);
    } 

    removeValue(parameter, index) {
        const values = this.state.values;
        const items = [...this.state.values[parameter]];
        const hbrm = items.slice(0, index);
        const harm = items.slice(index + 1, items.length);
        const sliced = hbrm.concat(harm);
        values[parameter] = sliced;

        this.setState((p)=>{
            return {
                values: values
            }            
        })
    }

    addLease() {
        const lease = this.state.parameters.lease;
        if (!lease || this.state.values.leases.includes(lease)) {
            return;
        }
        const values = this.state.values;
        let leases = [...this.state.values.leases];
        leases.push(lease);
        values.leases = leases;

        this.setState((p)=>{
            return {
                values: values
            }            
        })
    }

    async getLeases(e) {
        this.loadingLeases = true;
        let leases = [];

        if (this.state.parameters.filter || !this.state.customFilters) {
            leases = await getLeases(this.state.parameters.filter, this.state.parameters.operator);
        }
        this.loadingLeases = false;
        this.setState((p)=>({leases: leases}));
    }

    removeLease(index) {
        this.removeValue('leases', index);
    }

    setParameter(parameter, e) {
        let value;
        if (e && e.target) {
            value = e.target.value;
        } else {
            value = e;
        }

        if (value == '0') {
            value = null;
        }

        this.setState((p)=>{
            let parameters = p.parameters;
            let values = p.values;
            parameters[parameter] = value;
            const newState = {parameters: parameters}
            if (parameter == 'filter' && !value) {
                newState['customFilters'] = true;
            }

            
            if (parameter == "operator") {
                values['leases'] = [];
                newState['values'] = values;
                newState['areas'] = null;
                newState['leases'] = null;
                newState.parameters.area = null;
            }
            if (parameter == "filter") {
                values['leases'] = [];
                newState['values'] = values;
                newState['leases'] = null;
                newState.parameters.lease = null
            }

            return newState
        })
    }

    async getAreas() {
        const areas = await getAreas();
        this.setState(()=>({areas: areas}));
    }

    async getFilters() {
        const filters = await getFilters();
        this.setState(()=>({filters: filters}));
    }

    async getOperators() {
        const operators = await getOperators();
        this.setState(()=>({operators: operators}));
    }

    async generateReports() {
        this.parent.navigator.loadView();
        const reportsQuery = {};
        reportsQuery['area'] = this.state.parameters.area;
        reportsQuery['pad_filter'] = this.state.parameters.filter;
        reportsQuery['operator'] = this.state.parameters.operator;
        reportsQuery['report_type'] = this.state.parameters.reportType;

        // Remove this
        // reportsQuery['refresh'] = "true";

        reportsQuery['production_dates'] = this.state.values.productionDates;
        reportsQuery['dates_as_range'] = this.state.dateAsRange;
        reportsQuery['leases'] = this.state.values.leases;
        console.log(reportsQuery);
        let documents = this.parent.state.documents;
        
        const docs = await searchReports(reportsQuery);
        // let docs = generateDocuments(
            //     this.state.values.productionDates,
            //     this.state.values.leases
            // )
            
        let oldDocuments = documents;
        documents = [...docs];
        
        
        if (oldDocuments) {
            documents = [...oldDocuments.filter(o=>!documents.some(d=>d.linkkey === o.linkkey && d.proddate === o.proddate)), ...documents]
        }

        console.log(documents);
        this.parent.loadDocuments([...documents]);
        
        // this.setState(()=>({collapsed: true}))
    }

    render() {

        let areas;
        let filters;
        let leases = [];
        let operators = [];

        let selectedOperator;

        if (this.state.parameters.operator) {
            selectedOperator = this.state.parameters.operator;
        }

        if (this.state.operators) {

            operators = this.state.operators.map((operator)=>{
                    let key = `${operator.FONUMBER}`;
                    return {
                        key: key,
                        value: (
                        <>
                        <p>{operator.OPNAME}</p>
                        <p><span>FO</span>{` ${operator.FONUMBER} `}<span>OPC</span>{` ${operator.OPCODE} `}<span>HID</span>{` ${operator.OPHID}`}</p>
                        </>),
                        selected: (selectedOperator == key) 
                    }
                })
        } else {
            this.getOperators();
        }

        if (this.state.filters) {
            filters = this.state.filters.map((filter)=>{
                return (
                    <option key={uuid()} value={filter}
                        selected={filter == this.state.parameters.filter ? 'selected' : ''}>
                        {filter}
                    </option>
                )
            })
        } else if (this.state.customFilters){
            this.getFilters();
        }

        if (this.state.leases && (this.state.parameters.filter || !this.state.customFilters)) {
            leases = this.state.leases.map((lease) => {
                return (
                    {
                        key: lease,
                        value: (
                            <p>{lease}</p>
                        )
                    }
                )
            })
        } else if (!this.state.leases && (this.state.parameters.filter || !this.state.customFilters) && !this.loadingLeases) {
            this.getLeases();
        }

        let rangeToggler = (
            <div className={this.state.dateAsRange ? `toggler` : 'toggler disable'} onClick={()=>this.setState((p)=>({dateAsRange: !p.dateAsRange}))}>
                <span className={this.state.dateAsRange ? `check-filter` : 'check-filter disable'}></span>
            </div>
        )

        let productionDatesView = (
            <>
                <div style={{display: 'flex'}}>
                    <h4>Production Dates</h4>
                    {rangeToggler}
                    <p style={{marginLeft: '12px'}}>use as range</p>
                </div>
                
                <div className="select-grid-2">
                    <select onChange={(e)=> this.setParameter('year', e)}>
                        <option value="0">Year</option>
                        {
                            years.map((year) => {
                                return (<option value={year}>{year}</option>);
                            })
                        }
                    </select>
                    <select onChange={(e)=> this.setParameter('month', e)}>
                        <option value="0">Month</option>
                        {
                            months.map((month) => {
                                return (<option value={month}>{month}</option>);
                            })
                        }
                    </select>
                    <button className="add-btn" onClick={()=>this.addDate()}></button>
                </div>
                <div className="multi-options">
                    {
                        this.state.values.productionDates.map((date, index)=>{
                            let [year, month] = date.split('-')
                            return (
                                <div key={uuid()} className="option">
                                    <p>{months[Number(month) - 1]} / {year}</p>
                                    <button className="icon icon-cancel"
                                    onClick={()=>this.removeDate(index)}
                                    ></button>
                                </div>
                            );
                        })
                    }
                </div>
            </>
        )


        let operatorView = (
            <>
            
                <h4>Operator</h4>
                <OperatorSelect parent={this} key={uuid()} options={operators}></OperatorSelect>
            </>
        )

        let toggler = (
            <div className={this.state.customFilters ? `toggler` : 'toggler disable'} onClick={()=>this.setState((p)=>({customFilters: !p.customFilters}))}>
                <span className={this.state.customFilters ? `check-filter` : 'check-filter disable'}></span>
            </div>
        )

        let filterView = (
            <>
                <br></br>
                <div style={{display: 'flex'}}>
                    <h4>PAD Filters</h4>
                    {toggler}
                    <p style={{marginLeft: '12px'}}>enable grouping</p>
                </div>
                {
                    this.state.customFilters && 
                    <select onChange={(e)=>this.setParameter('filter', e)}>
                        <option value="0">Select Filter</option>
                        {filters}
                    </select>
                }
                
                
            </>
        )

        let leasesView = (
            <>
                <h4>Federal Lease</h4>

                <LeaseSelector parent={this} key={uuid()} options={leases}></LeaseSelector>
                
                <div className="multi-options">
                    {
                        this.state.values.leases.map((lease, index)=>{
                            return (
                                <div key={uuid()} className="option">
                                    <p>{lease}</p>
                                    <button className="icon icon-cancel"
                                    onClick={()=>this.removeLease(index)}
                                    ></button>
                                </div>
                            );
                        })
                    }
                </div>
            </>
        )

        let reportTypeView = (
            <>
                <h4>Report Type</h4>
                <div className="report-types">
                    <h1 className={this.state.parameters.reportType == 'original' ? 'selected' : ''}
                    onClick={()=>this.setParameter('reportType', 'original')}
                    >Original</h1>
                    <h1 className={this.state.parameters.reportType == 'correcting' ? 'selected' : ''}
                    onClick={()=>this.setParameter('reportType', 'correcting')}
                    >Correcting</h1>
                    <h1 className={this.state.parameters.reportType == 'replacing' ? 'selected' : ''}
                    onClick={()=>this.setParameter('reportType', 'replacing')}
                    >Replacing</h1>
                </div>
            </>
        )

        let generateReportsButton = (
            <>
                <button className={this.state.leases ? "generate-btn": "generate-btn disable"} onClick={()=>this.state.leases && this.generateReports()}>Generate Reports</button>
            </>
        )

        let content;

        if (!this.state.parameters.reportType) {
            content = (
                <>
                {reportTypeView}
                </>
            )
        } else if (!this.state.parameters.operator || (!this.state.parameters.filter && this.state.customFilters)) {
            content = (
                <>
                <div className="editable">
                    <h4>Report Type</h4>
                    <img className="icon edit"
                    onClick={()=>this.setParameter('reportType', null)}/>
                </div>
                <p>{this.state.parameters.reportType}</p>
                {operatorView}
                {filterView}
                </>
            )
        } else {
            content = (
                <>
                <div className="editable">
                    <h4>Report Type</h4>
                    <img className="icon edit"
                    onClick={()=>this.setParameter('reportType', null)}/>
                </div>
                    <p>{this.state.parameters.reportType}</p>
                <div className="editable">
                    <h4>Operator</h4>
                    <img className="icon edit"
                    onClick={()=>this.setParameter('operator', null)}/>
                </div>
                    <p>{this.state.parameters.operator}</p>
                <div className="editable">
                    <h4>PAD Filters</h4>
                    <img className="icon edit"
                    onClick={()=>this.setParameter('filter', null)}/>
                </div>
                    <p>{this.state.parameters.filter || 'No filter Selected'}</p>
                    {productionDatesView}
                    <br></br>
                    {leasesView}
                    <br></br>
                    {generateReportsButton}
                </>
            )
        }

        return (
            <div className="report-filter">

                <h4>Report Filler</h4>
                <p>{this.state.userData.email}</p>
                {content}
                <br></br>
                <button className="refresh-btn" onClick={()=>resetAllFilters()}>Refresh filters data</button>
            </div>
        );
    }
}
