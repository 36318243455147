import React, { cloneElement } from "react";
// import ReportSearch from "../../components/ReportSearch";
import ReportNavigator from "../../components/ReportNavigator";
import icon from "../../../images/icons/cropped-favicon-1-32x32.webp";
import uuid from "react-uuid";
import "./styles.css";
import "./tooltip.css";
import ReportSearchAlternate from "../../components/ReportSearch/alternate";
import { userinfo, logout } from "../../hooks/User";
import { removeSession } from "../../utils/tokens";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      userData: props.userData,
    };
  }

  toggle() {
    this.setState((p) => ({ collapsed: !p.collapsed }));
  }

  render() {
    let userData;
    if (this.state.userData) {
      userData = (
        <>
          <h1>{this.state.userData.name}</h1>
          <h2>Company/Team</h2>
        </>
      );
    }
    let content = (
      <div className="user-info">
        <div className="profile">
          <button
            className="icon profile-icon icon-cancel"
            onClick={() => this.toggle()}
          ></button>
          <div>{userData}</div>
        </div>
        <button onClick={() => logout()}>Logout</button>
      </div>
    );

    if (this.state.collapsed) {
      content = (
        <div className="collapsed-user tooltip" onClick={() => this.toggle()}>
          <img className="icon-profile"></img>
          <span className="tooltiptext">{this.state.userData.email}</span>
        </div>
      );
    }

    return <>{content}</>;
  }
}

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.state = {
      userData: props.userData,
    };
  }

  render() {
    return (
      <div className="side-bar">
        <User userData={this.state.userData}></User>
        <div className="logo-header">
          <img src={icon}></img>
          <h1>Hilcorp OGOR - ONRR</h1>
        </div>
      </div>
    );
  }
}

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: null,
      loggeduser: null,
    };
  }
  async loadDocuments(documents, newDocs) {
    
    this.setState(() => ({ documents: documents }));
  }

  setNavigator(navigator) {
    this.navigator = navigator;
  }
  setSearch(search) {
    this.search = search;
  }

  async preloader() {
    let user = await userinfo();
    if (user) {
      this.setState(() => ({ loggeduser: user }));
    }
  }

  render() {
    if (!this.state.loggeduser) {
      this.preloader();
      return <></>;
    } else {
      return (
        <div className="main-container">
          <SideBar
            parent={this}
            userData={this.state.loggeduser.user}
          ></SideBar>
          <ReportSearchAlternate
            parent={this}
            userData={this.state.loggeduser.user}
          ></ReportSearchAlternate>
          <ReportNavigator
            key={uuid()}
            documents={this.state.documents}
            parent={this}
            userData={this.state.loggeduser.user}
          ></ReportNavigator>
        </div>
      );
    }
  }
}
