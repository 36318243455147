import { getToken, saveToken } from "../utils/tokens";
import { getIP } from "./env";

const IP = getIP();

async function generateHeaders() {
    return {
        "Authorization": `Bearer ${await getToken()}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}

async function generateFetchConfig() {
    return {
        mode: 'cors',
        headers: await generateHeaders()
    }
}

async function userinfo() {
    const req = await fetch(
        `${IP}/userinfo`,
        await generateFetchConfig()
    )

    if (req.status == 401) {
        window.location.replace(`${IP}/login`);
    } else if (req.status == 200){
        const data = await req.json();
        console.log(data)
        return data;
    }
}

async function login() {
    window.open(`${IP}/login`, '_self');
    // const req = await fetch(
    //     `${IP}/login`,
    //     await generateFetchConfig()
    // )

    // if (req.redirected) {
    //     console.log('redirect');
    //     console.log(req.url);
    //     window.open(req.url, '_self');
    // } else if (req.status == 200){
    //     const data = await req.json();
    //     console.log(data)
    //     saveToken(data.session);
    //     window.open(`${IP}`, '_self');
    // }
}

async function getSessionID() {
    const req = await fetch(
        `${IP}/api/v1/login`,
        await generateFetchConfig()
    )

    if (req.redirected) {
        console.log('redirect');
        window.location.href = req.url;
    } else if (req.status == 200){
        const data = await req.json();
        console.log(data)
        saveToken(data.access_token);
    }
}

async function logout() {
    window.open(`${IP}/logout`, '_self');
}

export {
    userinfo,
    login,
    generateHeaders,
    generateFetchConfig,
    logout
}