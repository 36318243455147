
const line = {
    action_code: '12',
    api_well_number: {
        state: '42',
        country: '120',
        sequence: '44444',
        side_track: '02'
    },
    production_interval: 30,
    operator_well_number: 'abcdefg12345mkasd',
    well_status_code: '1',
    production_volumes: {
        oil: 108,
        gas: 45,
        water: 1008,
        injection: 30000
    }
};

const report = {
    report_type: 'Original',
    production_month: 'April / 2023',
    onrr_agency_lease_number: '12345678',
    onrr_operator_number: 'abcdefghji',
    operator_name: 'Bill',
    operator_lease_name: 'Bless the Lease',
    operator_lease_number: 'anyrandomnumbr',
    totals: {
        oil: 1080,
        gas: 450,
        water: 1008,
        injection: 30000
    },
    lines: [
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line)),
        JSON.parse(JSON.stringify(line))
    ]
};

const mockDocuments = [
    {
        name: 'April / 2023 - abcdef',
        reports: {
            A: JSON.parse(JSON.stringify(report)),
            B: JSON.parse(JSON.stringify(report)),
            C: JSON.parse(JSON.stringify(report)),
        }
    },
    {
        name: 'June / 2023 - 123456789',
        reports: {
            A: JSON.parse(JSON.stringify(report)),
            B: JSON.parse(JSON.stringify(report)),
            C: JSON.parse(JSON.stringify(report)),
        }
    }
];

function generateDocuments(prodDates, leases) {
    let documents = [];
    leases.forEach((lease) => {
        prodDates.forEach((prodDate) => {
            let blankDoc = {
                name: `${prodDate} - ${lease}`,
                reports: {
                    A: JSON.parse(JSON.stringify(report)),
                    B: JSON.parse(JSON.stringify(report)),
                    C: JSON.parse(JSON.stringify(report)),
                }
            }
            documents.push(blankDoc);
        })
    });
    return documents;
}


export {
    mockDocuments,
    generateDocuments
}