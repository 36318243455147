import React from "react";
import uuid from 'react-uuid';
import { sleep } from "../../../utils/sleep";
import './styles.css';


/**
 * Lines Section
 * 
 */


class ReportLine extends React.Component {
    constructor(props) {
        super(props);
        this.parent = props.parent;
        this.scroller = props.scroller;
        this.itemRef = React.createRef();
        this.state = {
            line: props.line,
            index: props.index,
            issue: props.issue,
        }
    }

    checkIssue() {
        let newState = !this.state.line.active;
        this.parent.updateIssueItem(this.state.index, 'active', newState);
        this.parent.updateIssueReport('scrollTop', this.state.index);
    }

    async scrollToItem() {
        await sleep(4);
        this.itemRef.current.scrollIntoView({behavior:'auto',block:'center'});
    }

    renderFromData() {
        return <></>;

    }

    render() {
        const line = this.state.line;
        const index = this.state.index;
        let lineNumber = index + 1;
        let styleIndex = this.index % 2;
        let lineStyle = this.state.issue ? `line issuing ${this.type}` : `line ${this.type}`;
        let accent = this.state.issue ? `accent issuing ${this.type}`: `accent ${this.type}`;

        if (this.state.line.active) {
            lineStyle = lineStyle + ` active-issue ${this.type}`
        }

        const report = this.parent.state.reportData.reports[this.parent.state.currentReport];

        if (report.scrollTop == this.state.index && this.state.line.active && report.issue) {
            this.scrollToItem();
            lineStyle = lineStyle + ' focused'
        }
        
        if (report.approved) {
            lineStyle = lineStyle + ' approved'
            accent = accent + ' approved'
        }

        lineStyle = lineStyle + ` index-${styleIndex}`;

        return(
            <div className={lineStyle} ref={this.itemRef} onClick={()=>{report.issue && this.checkIssue()}}>
                {
                    this.state.issue && 
                    <input type="checkbox" checked={this.state.line.active}/>
                }
                {
                    this.renderFromData()
                }
            </div>
        );
    }
}



class ReportALine extends ReportLine {
    constructor(props) {
        super(props);
        this.type = 'line-a'
        this.index = this.state.index;
    }

    renderFromData() {
        const line = this.state.line;
        let accent = this.state.issue ? 'accent issuing': 'accent';
        return (
            <>
                <p>{line.line_number}</p>
                <p>{line.action_code}</p>
                <p className={accent}>{line.api_well_number.state}</p>
                <p className={accent}>{line.api_well_number.country}</p>
                <p className={accent}>{line.api_well_number.sequence}</p>
                <p className={accent}>{line.api_well_number.side_track}</p>
                <p>{line.production_interval}</p>
                <p>{line.operator_well_number}</p>
                <p>{line.well_status_code}</p>
                <div className="volumes">
                    <p>
                    <span>Oil/Condensate [BBL]: </span>{line.production_volumes.oil}
                    <br></br>
                    <span>Gas [MCF]: </span>{line.production_volumes.gas}
                    <br></br>
                    <span>Water [BBL]: </span>{line.production_volumes.water}
                    </p>
                </div>
                <div className="volumes">
                    <p>
                    <span>Injection Volume [BBL/MCF]: </span>{line.production_volumes.injection}
                    <br></br>
                    <span>Days produced: </span>{line.days_reported}
                    </p>
                </div>
            </>
        )
    }
}

class ReportBLine extends ReportLine {
    constructor(props) {
        super(props);
        this.type = 'line-b'
        this.index = this.state.line.referenced_line;
    }

    renderFromData() {
        const line = this.state.line;
        let accent = this.state.issue ? 'accent issuing': 'accent';
        return (
            <>
                <p>{line.index}</p>
                <p className={accent}>{line.referenced_line}</p>
                <p>{line.disposition_code}</p>
                <p>{line.metering_point_number}</p>
                <p>{line.gas_plant_number}</p>
                <p>{line.api_gravity}</p>
                <p>{line.btu}</p>
                <div className="volumes">
                    <p>
                    <span>Oil/Condensate [BBL]: </span>{line.disposition_volumes.oil}
                    <br></br>
                    <span>Gas [MCF]: </span>{line.disposition_volumes.gas}
                    <br></br>
                    <span>Water [BBL]: </span>{line.disposition_volumes.water}
                    </p>
                </div>
            </>
        )
    }
}


class ReportCLine extends ReportLine {
    constructor(props) {
        super(props);
        this.type = 'line-c'
        this.index = this.state.line.referenced_line;
    }

    renderFromData() {
        const line = this.state.line;
        let accent = this.state.issue ? 'accent issuing': 'accent';
        return (
            <>
                <p>{line.index}</p>
                <p className={accent}>{line.referenced_line}</p>
                <p>{line.production_code}</p>
                <p>{line.inventory_storage_point_number}</p>
                <p>{line.metering_point_number}</p>
                <p>{line.api_gravity}</p>
                <p>{line.beginning_inventory}</p>
                <p>{line.production}</p>
                <p>{line.sales}</p>
                <p>{line.adjustment_code}</p>
                <p>{line.adjustment_volume}</p>
                <p>{line.ending_inventory}</p>
                {/* <div className="volumes">
                    <p>
                    <span>Oil/Condensate [BBL]: </span>{line.disposition_volumes.oil}
                    <br></br>
                    <span>Gas [MCF]: </span>{line.disposition_volumes.gas}
                    <br></br>
                    <span>Water [BBL]: </span>{line.disposition_volumes.water}
                    </p>
                </div> */}
            </>
        )
    }
}


/**
 * Headers Section
 * 
 */

class ReportAHeader extends React.Component {
    constructor(props) {
        super(props);
        this.parent = props.parent;
        this.state = {
            report: props.report
        }
    }

    render () {
        const report = this.state.report;

        return (
            <div className="doc-info">
                <div>
                    <h1>Report type: <span>{report.report_type}</span></h1>
                    <h1>Production Month: <span>{report.production_month}</span></h1>
                    <h1>ONRR or Agency Lease Number: <span>{report.onrr_agency_lease_number}</span></h1>
                    <h1>ONRR Operator Number: <span>{report.onrr_operator_number}</span></h1>
                    <h1>Operator Name: <span>{report.operator_name}</span></h1>
                    <h1>Operator Lease Name: <span>{report.operator_lease_name}</span></h1>
                    <h1>Operator Lease Number: <span>{report.operator_lease_number}</span></h1>
                </div>
                <div>
                    <h1><span>Total Production</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Oil/Condensate (BBL): {report.totals.oil}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Gas (MCF): {report.totals.gas}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Water (BBL): {report.totals.water}</span></h1>
                </div>
            </div>
        )
    }
}


class ReportBHeader extends React.Component {
    constructor(props) {
        super(props);
        this.parent = props.parent;
        this.state = {
            report: props.report
        }
    }

    render () {
        const report = this.state.report;

        return (
            <div className="doc-info">
                <div>
                    <h1>Report type: <span>{report.report_type}</span></h1>
                    <h1>Production Month: <span>{report.production_month}</span></h1>
                    <h1>ONRR or Agency Lease Number: <span>{report.onrr_agency_lease_number}</span></h1>
                    <h1>ONRR Operator Number: <span>{report.onrr_operator_number}</span></h1>
                    <h1>Operator Name: <span>{report.operator_name}</span></h1>
                    <h1>Operator Lease Name: <span>{report.operator_lease_name}</span></h1>
                    <h1>Operator Lease Number: <span>{report.operator_lease_number}</span></h1>
                </div>
                <div>
                    <h1><span>Disposition Volumes</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Oil/Condensate (BBL): {report.totals.oil}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Gas (MCF): {report.totals.gas}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Water (BBL): {report.totals.water}</span></h1>
                </div>
            </div>
        )
    }
}


class ReportCHeader extends React.Component {
    constructor(props) {
        super(props);
        this.parent = props.parent;
        this.state = {
            report: props.report
        }
    }

    render () {
        const report = this.state.report;

        return (
            <div className="doc-info">
                <div>
                    <h1>Report type: <span>{report.report_type}</span></h1>
                    <h1>Production Month: <span>{report.production_month}</span></h1>
                    <h1>ONRR or Agency Lease Number: <span>{report.onrr_agency_lease_number}</span></h1>
                    <h1>ONRR Operator Number: <span>{report.onrr_operator_number}</span></h1>
                    <h1>Operator Name: <span>{report.operator_name}</span></h1>
                    <h1>Operator Lease Name: <span>{report.operator_lease_name}</span></h1>
                    <h1>Operator Lease Number: <span>{report.operator_lease_number}</span></h1>
                </div>
                <div>
                    <h1><span>Totals</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Beginning Inventory: {report.totals.beginning_inventory}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Ending Inventory: {report.totals.ending_inventory}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Production: {report.totals.production}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Sales: {report.totals.sales}</span></h1>
                    <h1><span>&emsp;&emsp;&emsp;&emsp;Adjustments: {report.totals.adjustments}</span></h1>
                </div>
            </div>
        )
    }
}

/**
* Containers Section
*/


/**
 * Creational pattern
 * ReportContainer
 * Props:
 *      parent, React.Component
 *      lineType, ReportLine extended class (ReportALine, ReportBLine, ReportCLine)
 *      report, Report Data
 * 
 * Overrides:
 *      renderLinesHeader() - to match according to report layouts
 */
class ReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.parent = props.parent;
        this.lineType = props.lineType;
        this.scrollRef = React.createRef();
        this.state = {
            report: props.report
        }
    }

    getScrolledTop() {
        return this.scrollRef.current.offsetTop;
    }

    getSelectedLines() {
        let selectedCount = 0;
        let selectedLines = [];
        [...this.state.report.lines].forEach((line, index) =>{
            if (line.active) {
                selectedCount += 1;
                selectedLines.push(index)
            }
        });
        return {selectedCount, selectedLines}
    }

    moveToLine(step) {
        const report = this.state.report;
        const {selectedCount, selectedLines} = this.getSelectedLines();

        let index = selectedLines.indexOf(report.scrollTop);
        console.log(report.scrollTop, index)

        if (index + step < 0) {
            index = 0
        } else if (index + step >= selectedLines.length) {
            index = selectedLines.length - 1;
        } else {
            index = index + step;
        }

        this.parent.updateIssueReport('scrollTop', selectedLines[index]);


    }

    renderLinesHeader() {
        return (
            <></>
        )
    }

    render() {
        const report = this.state.report;
        const {selectedCount, selectedLines} = this.getSelectedLines();
        const LineComponent = this.lineType;
        return (
            <>
                {
                    this.renderLinesHeader()
                }
                <div className="lines-container" ref={this.scrollRef}>
                    {
                        report.lines.map((line, index)=>{
                            return <LineComponent scroller={this} parent={this.parent} key={uuid()} line={line} issue={report.issue} index={index}></LineComponent>
                        })
                    }
                </div>

                {
                    report.issue &&
                    <div className="issue-line-counter">
                        <p>selected lines: <span>{selectedCount}</span></p>
                        <button className="icon icon-chevron-down"
                        onClick={()=>this.moveToLine(1)}></button>
                        <button className="icon icon-chevron-up"
                        onClick={()=>this.moveToLine(-1)}></button>
                    </div>
                }
            </>
        );
    }
}

class ReportA extends ReportContainer {
    renderLinesHeader() {
        const report = this.state.report;
        return (
            <>
                <div className={"lines-container header"}>
                    <div className={(report.issue ? 'head-issue ' : '') + "head-line type-a"}>
                        {
                            report.issue && <p></p>
                        }
                        <p>LN</p>
                        <p>AC</p>
                        <p>API WELL NUMBER</p>
                        <p>PI</p>
                        <p>OPERATOR WELL NUMBER</p>
                        <p>WSC</p>
                        <p>Production Volumes</p>
                    </div>
                    <div className={"sub-head-line  " + (report.issue ? 'sub-head-issue  type-a' : 'type-a')}>
                        {
                            report.issue && <p></p>
                        }
                        <p></p>
                        <p></p>
                        <p>STATE</p>
                        <p>COUNTY</p>
                        <p>SEQUENCE</p>
                        <p>SIDE-TRACK</p>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </>
        )
    }
}

class ReportB extends ReportContainer {
    renderLinesHeader() {
        const report = this.state.report;
        return (
            <>
                <div className={"lines-container header"}>
                    <div className={(report.issue ? 'head-issue ' : '') + "head-line type-b"}>
                        {
                            report.issue && <p></p>
                        }
                        <p>LN</p>
                        <p>Index</p>
                        <p>Disp Code</p>
                        <p>Met Point Numb</p>
                        <p>Gas Plant Numb</p>
                        <p>API GRAVITY</p>
                        <p>BTU</p>
                        <p>Disposition Volumes</p>
                    </div>
                    <div className={"sub-head-line " + (report.issue ? 'sub-head-issue type-b' : 'type-b')}>
                        {
                            report.issue && <p></p>
                        }
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </>
        )
    }
}

class ReportC extends ReportContainer {
    renderLinesHeader() {
        const report = this.state.report;
        return (
            <>
                <div className={"lines-container header"}>
                    <div className={(report.issue ? 'head-issue ' : '') + "head-line type-c"}>
                        {
                            report.issue && <p></p>
                        }
                        <p>LN</p>
                        <p>Index</p>
                        <p>PRODUCTION CODE</p>
                        <p>INVENTORY STORAGE POINT NUMBER</p>
                        <p>METERING POINT NUMBER</p>
                        <p>API GRAVITY</p>
                        <p>BEGINNING INVENTORY (BBL)</p>
                        <p>PRODUCTION (BBL)</p>
                        <p>SALES (BBL)</p>
                        <p>ADJUSTMENTS</p>
                        <p>ENDING INVENTORY</p>
                    </div>
                    <div className={"sub-head-line " + (report.issue ? 'sub-head-issue type-c' : 'type-c')}>
                        {
                            report.issue && <p></p>
                        }
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p>Code</p>
                        <p>Volume (BBL)</p>
                        <p></p>
                    </div>
                </div>
            </>
        )
    }
}


export {
    ReportA,
    ReportAHeader,
    ReportALine,
    ReportB,
    ReportBHeader,
    ReportBLine,
    ReportC,
    ReportCHeader,
    ReportCLine
};