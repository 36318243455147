import React from "react";
import { sleep } from "../../utils/sleep";

class OperatorSelect extends React.Component {
    constructor(props) {
        super(props);
        let selected = props.selected;
        this.parent = props.parent;
        if (!selected && props.options.length > 0) {
            props.options.map((option)=>{
                if (option.selected) {
                    selected = option;
                }
            })
            if (!selected) {
                selected = props.options[0]
                if (this.parent.state.parameters.operator != selected.key) {
                    this.parent.setParameter('operator', selected.key);
                }
            }
        } else {
            selected = {
                key: null,
                value: null
            }
        }
        this.state = {
            value: selected.value,
            key: selected.key,
            collapsed: true,
            options: props.options
        }
    }

    setValue(key, value) {
        this.setState(()=>({value: value, key: key, collapsed: true}))
        this.parent.setParameter('operator', key);
    }

    toggle() {
        this.setState((p)=>({collapsed: !p.collapsed}))
    }

    render() {
        let options = this.state.options.map((option)=>{
            return (
                <div 
                    className="custom-option" onClick={()=>this.setValue(option.key, option.value)}>
                    {option.value}
                </div>
            )
        })

        let selected = (
            <div
                className="selected-collapsed"
                onClick={()=>this.toggle()}
                >{this.state.value || 'Loading Options...'}
            </div>
        );
        return (
            <div className="custom-select">
                {this.state.collapsed && selected}
                {!this.state.collapsed && (
                    <div className="custom-options">
                    {options}
                    </div>
                )}
            </div>
        );
    }
}

class LeaseSelector extends React.Component {
    constructor(props) {
        super(props);
        let selected = props.selected;
        this.parent = props.parent;
        if (!selected && props.options.length > 0) {
            props.options.map((option)=>{
                if (option.selected) {
                    selected = option;
                }
            })
            if (!selected) {
                selected = props.options[0]
            }
        } else {
            selected = {
                key: null,
                value: null
            }
        }
        this.state = {
            value: selected.value,
            key: selected.key,
            collapsed: true,
            options: props.options,
            filtered: null
        }
    }

    render() {
        let options;
        
        if (!this.state.collapsed && this.state.filtered) {
            options = this.state.filtered;
        } else {
            options = this.state.options;
        }
        
        options = options.map((option)=>{
            return (
                <div 
                    className="custom-option" onClick={()=>this.setValue(option.key, option.value)}>
                    {option.value}
                </div>
            )
        })

        let selected = (
            <>
            <div
                className="selected-collapsed lease"
                onClick={()=>this.toggle()}
                ><h1>{this.state.value || 'Loading Leases...'}</h1>
            </div>
            <button className="add-btn" onClick={()=>this.addLease()}></button>
            </>
            
        );
        return (
            <div className="custom-select lease">
                {this.state.collapsed && selected}
                {!this.state.collapsed && (
                    <>
                        <div className="lease-search">
                            <input placeholder="type to search..." onChange={(e)=>this.search(e.target.value)}></input>
                        </div>
                        <div className="custom-options leases">
                        {options}
                        </div>
                    </>
                    
                )}
            </div>
            
        );
    }

    setValue(key, value) {
        this.setState(()=>({value: value, key: key, collapsed: true}))
        // this.parent.setParameter('operator', key);
    }

    toggle() {
        this.setState((p)=>({collapsed: !p.collapsed}))
    }

    async addLease() {
        this.parent.setParameter('lease', this.state.key)
        await sleep(200);
        this.parent.addLease()
    }

    search(value) {
        let filtered = [];
        this.state.options.forEach((option)=>{
            if (option.key.toLowerCase().includes(value.toLowerCase())) {
                filtered.push(option)
            }
        })
        this.setState(()=>({filtered: filtered}))
        console.log(value);
    }
}

export {
    LeaseSelector,
    OperatorSelect
}