async function saveToken(token) {
    localStorage.setItem('token', JSON.stringify(token));

}

async function getToken() {
    const token = localStorage.getItem('token');
    return JSON.parse(token)
}

function removeSession() {
    localStorage.clear();
    window.location.reload();
}

export {
    saveToken,
    getToken,
    removeSession
}