import React from "react";
import { generateDocuments, mockDocuments } from "./constants";
import { getReports } from "../../hooks/Search";
import { 
    ReportA,
    ReportAHeader,
    ReportALine,
    ReportB,
    ReportBHeader,
    ReportBLine,
    ReportC,
    ReportCHeader,
    ReportCLine
} from "./ReportDetail";
import './styles.css';
import { sleep } from "../../utils/sleep";
import { getLocalReport, saveLocalReport, deleteLocalReport, resetAllFilters, clearData } from "../../utils/session";
import uuid from "react-uuid";
import { removeSession } from "../../utils/tokens";
import { download, bulkDownload } from "../../hooks/Download";

import { LeaseSelector } from "../ReportSearch/Selectors";




const reportsMap = {
    'A': {
        header: ReportAHeader,
        content: ReportA,
        line: ReportALine
    },
    'B': {
        header: ReportBHeader,
        content: ReportB,
        line: ReportBLine
    },
    'C': {
        header: ReportCHeader,
        content: ReportC,
        line: ReportCLine
    }
}


export default class ReportNavigator extends React.Component {
    constructor(props) {
        super(props);
        const documents = props.documents;
        this.parent = props.parent;
        this.parent.setNavigator(this);
        this.state = {
            reports: documents,
            currentTab: documents ? documents.length - 1 : 0,
            currentReport: 'A',
            issue: false,
            emptyTab: false,
            optionMenu: null,
            removeDocument: null,
            alertConfig: null,
            reportData: null,
            searchTab: false,
            status: null,
            loadingReportsInBackground: false,
        }
        this.tabRef = React.createRef();
        this.navTabBar = React.createRef();
        this.saveRecent(documents);

        let comp =  this;

        // Keyboard Shortcuts
        document.addEventListener("keypress", (e) => {
            console.log(e);
            // Ctrl + Shift + Q Close all the tabs
            if (e.ctrlKey && e.shiftKey && e.code == "KeyQ") {
              e.preventDefault();
              comp.closeAllTabs();
            }

            // Ctrl + Shift + F Open find tabs window
            if (e.ctrlKey && e.shiftKey && e.code == "KeyF") {
                e.preventDefault();
                comp.openTabSearch();
              }
            
            if (e.ctrlKey && e.shiftKey && e.code == "KeyZ") {
                e.preventDefault();
                comp.resetAllFilters();
                removeSession();
            }
          });
    }

    loadView() {
        this.setState(()=>({reportData: 'loading'}));
    }

    resetAllFilters() {
        resetAllFilters();
    }

    openTabSearch() {
        this.setState((p)=>({searchTab: !p.searchTab}))
    }

    closeAllTabs() {
        this.setState(()=>({reports: null}))
    }
    approveReport() {
        const report = this.state.reportData;
        report.reports[this.state.currentReport]['approved'] = !report.reports[this.state.currentReport]['approved'];
        report.reports[this.state.currentReport]['issue'] = false;

        this.setState(()=>({reportData: report, issue: false}));
    }

    enableIssuing() {

        let issue = !this.state.issue;
        const report = this.state.reportData;
        report.reports[this.state.currentReport].issue = issue;
        this.setState(()=>({issue: issue, reportData: report}));
    }

    updateIssueItem(index, key, data) {
        const report = this.state.reportData;
        report.reports[this.state.currentReport].lines[index][key] = data;
        this.setState(()=>({reportData: report}));
    }

    updateIssueReport(key, data) {
        const report = this.state.reportData;
        report.reports[this.state.currentReport][key] =  data;
        this.setState(()=>({reportData: report}));
    }

    changeReportTab(reportType) {
        let issue = this.state.reportData.reports[reportType].issue;
        this.setState(()=>({currentReport: reportType, issue: issue}));
    }

    async removeDocument(e, index) {
        await sleep(5);
        let currentTab = this.state.currentTab;
        if (currentTab >= 1) {
            currentTab = currentTab - 1;
        } else {
            currentTab = 0;
        }
        this.setState((p)=>({currentTab: currentTab, alertConfig: null, reportData: null}));

        let documents = this.state.reports;
        deleteLocalReport(documents[index].linkkey, documents[index].proddate);
        documents.splice(index, 1);
    }

    async addDocument(filter) {
        let documents = this.state.reports ? this.state.reports : [];
        
        documents.push(filter);

        this.saveRecent(documents)

        this.setState(()=>({
            reports: documents,
            currentTab: documents.length - 1,
            emptyTab: false,
            alertConfig: null,
            reportData: null
        }))
    }

    saveRecent(documents) {
        let docuNames;
        if (documents) {
            docuNames = documents.map((doc) => {
                return {linkkey: doc.linkkey, proddate: doc.proddate}
            })
        } else {
            docuNames = []
        }

        let oldDocs = localStorage.getItem('recent');

        if (!oldDocs) {
            oldDocs = [];
        }  else {
            oldDocs = JSON.parse(oldDocs);

        }

        let dedupDocuNames = [];

        docuNames.forEach((doc)=>{
            let add = true;
            oldDocs.forEach((oldDoc)=>{
                if (oldDoc.name == doc.name) {
                    add = false;
                }
            });
            if (add) {
                dedupDocuNames.push({...doc});
            } 
        });


        oldDocs = [...oldDocs, ...dedupDocuNames];

        localStorage.setItem('recent',JSON.stringify([...oldDocs]));
    }

    getRecent() {
        const recent = localStorage.getItem('recent');
        if (!recent) {
            return []
        } else {
            let list = JSON.parse(recent);
            list.reverse();
            return list;
        }
    }

    async getAllReports() {
        const reports = await getReports();
        this.setState(()=>({allReports: reports}));
    }


    async loadLocalReportsinBackground(filters) {
        if (this.state.loadingReportsInBackground) {
            return;
        }
        this.setState(()=> ({loadingReportsInBackground: true}));
        filters.forEach(async (filter) => {
            let repData = getLocalReport(filter.linkkey, filter.proddate, filter.report_type);
            if (!repData) {
                repData = await getReports(filter);
                
                if (repData) {
                    saveLocalReport(repData, filter.linkkey, filter.proddate, filter.report_type);
                }
            }
        });
    }

    async getSingleReport(commit=true, index=this.state.currentTab) {
        let filter = this.state.reports[index];
        let report = getLocalReport(filter.linkkey, filter.proddate, filter.report_type);
        if (!report) {
            report = await getReports(filter);
            if (!report) {
                return null;
            } else {
                
                saveLocalReport(report, filter.linkkey, filter.proddate, filter.report_type);
            }
            
        }
        
        if (commit) {
            this.setState(()=>({reportData: report}));
        } else return report;
    }

    async changeTab(e, index) {
        console.log(e.target.tagName);
        if (e.target.tagName == 'SPAN') {
            return;
        }
        if (index != this.state.currentTab) {
            this.setState(()=>({
                reportData: 'loading',
                currentTab: index
            }));

            let reportData = await this.getSingleReport(false, index);
            if (!reportData) {
                let reports = this.state.reports.splice(index, 1);
                if (reports && reports.length > 0) {
                    this.setState(()=>({
                        currentTab: reports.length - 1
                    }));
                    return;
                } else {
                    this.setState(()=>({
                        reports: null,
                        emptyTab: true,
                        downloadManager: false,
                        reportData: null,
                        currentTab: 0
                    }));
                    return;
                }
                
            }
            const issue = reportData.reports[this.state.currentReport].issue;
            const approved = reportData.reports[this.state.currentReport].approved;

            this.setState(()=>({
                currentTab: index,
                emptyTab: false,
                downloadManager: false,
                issue: issue,
                approved: approved,
                reportData: reportData
            }))
        } else {
            this.setState(()=>({emptyTab: false, downloadManager: false}))
        }
    }

    async scrollToTab() {
        await sleep(5);
        this.tabRef.current.scrollIntoView({behavior:'auto',block:'center'});
    }

    async openDocument(id) {
        
    }

    tabOptions(e, index) {
        e.preventDefault();
        let opMenu = {x: e.clientX, index: index};
        if (this.state.optionMenu) {
            opMenu = null;
        }
        this.setState(()=>({optionMenu: opMenu}))
    }

    // Alert menus

    changeVersion() {
        let alertConfig = {
            title: 'Choose the version to open',
            message: (
                <div className="versions-list">
                    <p>Version 1 - 8/05/2023 16:53</p>
                    <p>Version 2 - 10/05/2023 16:53</p>
                </div>
            ),
            denyCallback: () => this.setState(()=>({alertConfig: null})),
            denyOption: 'Close',
            confirmCallback: null,
            confirmOption: null
        }
        this.setState(()=>({alertConfig: alertConfig}))
    }

    confirmRemove(index) { 
        let alertConfig = {
            title: 'Delete the document?',
            message: this.state.reports[index].name,
            denyCallback: (e) => this.setState(()=>({alertConfig: null})),
            denyOption: 'Cancel',
            confirmCallback: (e) => this.removeDocument(e, index),
            confirmColor: 'red',
            confirmOption: 'Delete'
        }
        this.setState(()=>({alertConfig: alertConfig}))
    }

    syncReport() {
        let alertConfig = {
            title: 'Sync the report will create a new Version',
            message: 'Are you sure to update this to version x',
            denyCallback: () => this.setState(()=>({alertConfig: null})),
            denyOption: 'Cancel',
            confirmCallback: () => {
                console.log('sync to new version')
                this.setState(()=>({alertConfig: null}))
            },
            confirmOption: 'Continue with Sync',
            confirmColor: 'green'
        }
        this.setState(()=>({alertConfig: alertConfig}))
    }

    approveReport() {
        let alertConfig = {
            title: 'This action will create an Approved version that is not editable',
            message: 'Are you sure to mark this document as approved?',
            denyCallback: () => this.setState(()=>({alertConfig: null})),
            denyOption: 'Cancel',
            confirmCallback: () => {
                console.log('sync to new version')
                this.setState(()=>({alertConfig: null}))
            },
            confirmOption: 'Approve Document',
            confirmColor: 'green'
        }
        this.setState(()=>({alertConfig: alertConfig}))
    }

    async download(index, format) {
        this.setState(()=>({status: 'Downloading CSV File, this process could take a few minutes...'}));
        const filter = this.state.reports[index];
        await download(filter.linkkey, filter.proddate, format);
        this.setState(()=>({status: null}))
    }

    downloadFiles(index) {
        let report = this.state.reports[index];
        let linkkey = report.linkkey;
        let proddate = report.proddate;
        const lease = `${linkkey}-${proddate}`;
        let alertConfig = {
            title: 'Choose the format to download',
            message: (
                <div className="download-section">
                    <button onClick={()=>this.download(index, 'csv')}>CSV</button>
                    <button onClick={()=>this.download(index, 'ascii')}>ASCII</button>
                    <button>PDF / not enable</button>
                    <p>{lease}</p>
                </div>
            ),
            denyCallback: () => this.setState(()=>({alertConfig: null})),
            denyOption: 'Close',
            confirmCallback: null,
            confirmOption: null
        }
        this.setState(()=>({alertConfig: alertConfig}))
    }

    render() {
        if (!this.state.reportData && (this.state.reports && this.state.reports.length > 0)) {
            this.getSingleReport();
        }

        let searchTab;

        if (this.state.searchTab && this.state.reports) {
            let options = this.state.reports.map((report)=> {
                return {
                    key: `${report.linkkey}-${report.proddate}`,
                    value: (
                        <p>
                            {`${report.linkkey} / ${report.proddate}`}
                        </p>)
                }
            });
            searchTab = (
            <div className="search-tab">
                <h2>Search Tab</h2>
                <LeaseSelector options={options}></LeaseSelector>
            </div>)
        }

        let emptyTab = (
            <div className="navigation-menu">
                <div>
                    <h1>OGOR - ONRR Report Generator</h1>
                    <h2>Recent</h2>
                    {
                        this.getRecent().slice(0,20).map((recentTab) => {
                            return (
                                <p key={uuid()} 
                                    onClick={()=>this.addDocument(recentTab)}
                                >{recentTab.linkkey} / {recentTab.proddate}</p>)
                        })
                    }
                </div>
                <div></div>
                <div>
                    <p onClick={()=>clearData()}>Clear all data</p>
                    <h2>Need Support?</h2>
                    <button 
                        onClick={()=>window.open('mailto:daniel.rodriguez@teaminternational.com')}
                    >Email me</button>
                </div>
            </div>
        );
        if (!this.state.reports || this.state.reports.length == 0) {
            return (
                <div className="report-navigator" key={uuid()}>
                    {emptyTab}
                </div>
            );
        }

        /**
         * Generate Tab Views
         */

        let tabs = []

        if (this.state.reports) {
            this.loadLocalReportsinBackground(this.state.reports);
            tabs = this.state.reports.map((data, index) => {
                const className = this.state.currentTab == index && (!this.state.emptyTab && !this.state.downloadManager) ? 'active' : '';
    
                if (className == 'active') {
                    this.scrollToTab();
                }
                return (
                <h1 className={className} 
                    ref={className == 'active' && this.tabRef} 
                    key={uuid()}
                    onClick={(e)=>this.changeTab(e, index)}>{data.linkkey} / {data.proddate}
                    <span className="icon icon-options-tab" onClick={(e)=>this.tabOptions(e, index)}></span>
                    <span className="icon icon-close-tab" onClick={(e)=>this.removeDocument(e, index)}></span>
                </h1>);
            });    
        }

        // Add the download tab

        tabs.unshift((
            <h1 className={this.state.downloadManager ? `active` : 'empty-tab'} key={uuid()}
            onClick={()=>this.setState({downloadManager: true, emptyTab: false})}>Download Manager</h1>
        ))

        // Add the empty tab to the beginning

        tabs.unshift((
            <h1 className={this.state.emptyTab ? `active` : 'empty-tab'} key={uuid()}
            onClick={()=>this.setState({emptyTab: true, downloadManager: false})}>Recent Documents</h1>
        ))


        // End of Tabs


        if (this.state.reportData == 'loading' || (!this.state.reportData && this.state.reports && this.state.reports.length > 0) || this.state.status) {
            return (
                <>
                    <div className="report-navigator" key={uuid()}>
                        <div className="document-nav-tabs" ref={this.navTabBar} onWheel={(e)=>this.scrollNavBar(e)}>
                            {tabs}
                        </div>
                        <div className="loading">
                            <img className="icon icon-loading"></img>
                            <p>{this.state.status || "Loading Report..."}</p>
                        </div>
                    </div>
                </>
            )
        }

        let report = null;
        let reports = this.state.reportData.reports;

        if ((this.state.reportData && this.state.reportData != 'loading') && this.state.reports) {
            report = this.state.reportData.reports[this.state.currentReport];
        }

        const ComponentContent = reportsMap[this.state.currentReport].content;
        const ComponentHeader = reportsMap[this.state.currentReport].header;
        const ComponenLine = reportsMap[this.state.currentReport].line;
        let approvedState = report.approved;
        let reportContent;

        if (this.state.emptyTab) {
            reportContent = () => {return <>{emptyTab}</>}
        } else if (this.state.downloadManager) {
            reportContent = () => {return <DownloadManager documents={this.state.reports}></DownloadManager>}
        } else {
            reportContent = () => {return (
                <div className="report-content">
                    <ComponentContent key={uuid()} lineType={ComponenLine} report={report} parent={this}></ComponentContent>
                    <div className="export-container">
                        <div className="report-tabs">
                            <div className={this.state.currentReport == 'A' ? "report-tab active-report" : "report-tab"}
                            onClick={()=>{this.changeReportTab('A')}}>
                                <h1>Report A</h1>
                                <img className={"icon" + (reports['A'].issue ? " icon-issue" : ( reports['A'].approved ? " icon-approved" : " icon-incomplete"))}></img>
                            </div>
                            <div className={this.state.currentReport == 'B' ? "report-tab active-report" : "report-tab"}
                            onClick={()=>{this.changeReportTab('B')}}>
                                <h1>Report B</h1>
                                <img className={"icon" + (reports['B'].issue ? " icon-issue" : ( reports['B'].approved ? " icon-approved" : " icon-incomplete"))}></img>
                            </div>
                            <div className={this.state.currentReport == 'C' ? "report-tab active-report" : "report-tab"}
                            onClick={()=>{this.changeReportTab('C')}}>
                                <h1>Report C</h1>
                                <img className={"icon" + (reports['C'].issue ? " icon-issue" : ( reports['C'].approved ? " icon-approved" : " icon-incomplete"))}></img>
                            </div>
                        </div>
                        <ComponentHeader key={uuid()} report={report} parent={this}></ComponentHeader>
                        <div className="document-actions">
                            <button className={"approve" + (approvedState ? ' active': '')} onClick={()=>this.approveReport()}>Approve</button>
                            <button className={"issue" + (this.state.issue ? ' active' : '')} onClick={()=>this.enableIssuing()}>Issues</button>
                            {/* <button className={"delete"} onClick={()=>this.deleteDocument()}>Delete</button> */}
                        </div>
                    </div>
                    
                </div>
            )};
        }

        let tabsMenu;
        if (this.state.optionMenu) {
            tabsMenu = (
                <div 
                 onMouseLeave={()=>this.setState(()=>({optionMenu: null}))} 
                className="nav-options-menu"
                    style={{left: this.state.optionMenu.x - 260}}>
                        <div className="nav-option" onClick={()=>this.syncReport()}>
                            <p>Sync Report</p>
                            <span>Crtl + Shift + Q</span>
                        </div>
                        <div className="nav-option" onClick={()=> this.changeVersion()}>
                            <p>Change Version</p>
                            <span>Crtl + D</span>
                        </div>
                        <div className="nav-option" onClick={()=>this.confirmRemove(this.state.optionMenu.index)}>
                            <p>Delete Draft</p>
                            <span>Alt + Shift + R</span>
                        </div>
                        <hr></hr>
                        <div className="nav-option" onClick={()=>this.approveReport()}>
                            <p style={{color: 'green'}}>Approve This Version</p>
                        </div>
                        <div className="nav-option" onClick={()=>this.downloadFiles(this.state.optionMenu.index)}>
                            <p>Export Report</p>
                        </div>
                        <div className="nav-option" onClick={()=>this.setState({downloadManager: true, emptyTab: false})}>
                            <p>Export Multiple</p>
                        </div>
                </div>
            )
        }

        let deleteConfirm;

        if (this.state.alertConfig) {
            let alertConfig = this.state.alertConfig;
            deleteConfirm = <ReportAlert config={alertConfig}></ReportAlert>

        }

        return (
            <>
                {tabsMenu}
                {searchTab}
                <div className="report-navigator" key={uuid()}>
                    {deleteConfirm}
                    <div className="document-nav-tabs" ref={this.navTabBar} onWheel={(e)=>this.scrollNavBar(e)}>
                        {tabs}
                    </div>
                    {reportContent()}
                </div>
            </>
        );
    }

    scrollNavBar(e) {
        this.navTabBar.current.scrollLeft = this.navTabBar.current.scrollLeft + (e.deltaY / 2);
    }
}

class ReportAlert extends React.Component {
    constructor(props) {
        super(props);
        let config = props.config;
        this.context = props.context;
        this.state = {
            title: config.title,
            message: config.message,
            confirmOption: config.confirmOption,
            confirmCallback: config.confirmCallback,
            denyOption: config.denyOption,
            denyCallback: config.denyCallback,
            confirmColor: config.confirmColor

        }
    }
    callDeny() {
        if (this.state.denyCallback) {
            this.state.denyCallback();
        } else {
            console.log('No Callback defined');
        }
        
    }

    callConfirm() {
        this.state.confirmCallback();
    }

    render() {
        let config = this.state;
        return(
            <div className="report-alert">
                <div className="alert-card">
                    <h1>{config.title}</h1>
                    <p>{config.message}</p>
                    <div className="alert-options">
                        <button onClick={()=>this.callDeny()}>{config.denyOption}</button>
                        <div></div>
                        {
                            this.callConfirm &&
                            <button className="confirm-btn" 
                            onClick={(e)=>this.callConfirm()}
                            style={{
                                backgroundColor: config.confirmColor,
                                color: 'white'
                            }}
                            >{config.confirmOption}</button>
                        }
                        
                    </div>
                    
                </div>
            </div>
        )
    }
}


class DownloadManager extends React.Component {
    constructor(props) {
        super(props);

        let documents = props.documents.map((d)=>{
            d['selected'] = false;
            return d
        });

        this.state = {
            documents: documents,
            all: false,
            loading: false,
            filename: null,
            current: {
                linkkey: null,
                proddate: null,
                format: null
            },
            error: false,
        }
    }


    checkItem(e, index) {
        if (e.target.tagName == 'BUTTON') {
            return;
        }
        let reports = this.state.documents;
        reports[index]['selected'] = !reports[index]['selected'];
        this.setState((p)=>({documents: reports}));
    }

    selectAll() {
        let reports = this.state.documents;
        for (let rep of reports) {
            rep['selected'] = !this.state.all;
        }
        
        this.setState((p)=>({documents: reports, all: !p.all}));
    }


    async download(format) {
        if (!this.state.filename) {
            return;
        }
        let selected = [];
        this.state.documents.forEach((doc)=>{
            if (doc.selected) {
                selected.push(doc)
            }
        })

        if(selected.length<=0){
            this.setState(()=>({error:true, message: `Select at least one report`}));
            return;
        }

        console.log('Start the download...')
        console.log(selected);
        
        this.setState((p)=>({loading: true, message: `Downloading multiple reports this could take several seconds`, error:false}));
        
        await bulkDownload(format, selected, this.state.filename);

        this.setState((p)=>({loading: false, error:false}));
    }

    setFilename(filename) {
        this.setState(()=>({filename: filename}));
    }

    async singleDownload(linkkey, proddate, format) {
        this.setState(()=>({
            loading: true,
            message: `Downloading ${linkkey}-${proddate} this could take some time`,
            current: {
                linkkey: linkkey,
                proddate: proddate,
                format: format
            }
        }))
        await download(linkkey, proddate, format);
        this.setState(()=>({
            loading: false,
            current: {
                linkkey: null,
                proddate: null,
                format: null
            }
        }));
    }

    render() {
        let reports =  this.state.documents.map((report, index) =>{
            return (
                <div className={`select-item ${report.selected ? 'selected' : ''}`} onClick={(e)=> this.checkItem(e, index)}>
                    <input type="checkbox" checked={ report.selected }/>
                    {/* <img className="icon icon-file"></img> */}
                    <p>{report.linkkey}</p>
                    <p>{report.proddate}</p>
                    <button className={
                        (
                            this.state.current.linkkey == report.linkkey
                            && this.state.current.proddate == report.proddate
                            && this.state.current.format == 'ascii'
                        ) && "btn-loading"
                    } 
                    onClick={(e)=> this.singleDownload(report.linkkey, report.proddate, 'ascii')}>ascii
                    {
                        (
                            this.state.current.linkkey == report.linkkey
                            && this.state.current.proddate == report.proddate
                            && this.state.current.format == 'ascii'
                        ) ? <i className="icon icon-rolling"></i> : <></>
                    }
                    </button>

                    <button className={
                        (
                            this.state.current.linkkey == report.linkkey
                            && this.state.current.proddate == report.proddate
                            && this.state.current.format == 'csv'
                        ) && "btn-loading"
                    }
                    onClick={(e)=> this.singleDownload(report.linkkey, report.proddate, 'csv')}>csv
                    {
                        (
                            this.state.current.linkkey == report.linkkey
                            && this.state.current.proddate == report.proddate
                            && this.state.current.format == 'csv'
                        ) ? <i className="icon icon-rolling"></i> : <></>
                    }
                    </button>
                </div>
            )
        });

        let loading;
        let error;

        if (this.state.loading) {
            loading = (
                <>
                    <div className="download-loading loading">
                        <img className="icon icon-loading"></img>
                        <p>{this.state.message}</p>
                    </div>
                </>
            )
        }

        if (this.state.error) {
            error = (
                <>
                    <div className="download-loading loading">
                        <img className="icon icon-close-tab"></img>
                        <p>{this.state.message}</p>
                    </div>
                </>
            )
        }

        return(
            <>
                <div className="navigation-menu download">
                    <div>
                        <h1>Select the files to download</h1>                    
                        <div className="select-box-container">
                            <div className="select-item">
                                <input type="checkbox" onChange={()=>this.selectAll()}/>
                                <p>Lease Number</p>
                                <p>Production Date</p>
                                <p></p>
                            </div>
                            {reports}
                        </div>
                    </div>
                    <div className="export-window">
                        <h1>Export as</h1>
                        <label>Filename</label>

                        <input type="text" value={this.state.filename} onChange={(e)=> this.setFilename(e.target.value)}></input>

                        <div className="download-controls">
                            <button onClick={()=>this.download('csv')}>Download CSV</button>
                            <button onClick={()=>this.download('ascii')}>Download ASCII</button>
                        </div>

                        <div>
                            {loading}
                            {error}
                        </div>

                    </div>                    
                </div>
            </>
        );
    }
}