async function download(linkkey, proddate, format) {
    const req = await fetch(`/api/v1/reports/download?linkkey=${linkkey}&proddate=${proddate}&format=${format}`)

    if (req.status == 200) {
        const blob = await req.blob()
        let fileURL = window.URL.createObjectURL(blob);
        let a  = document.createElement('a');
        a.href = fileURL;
        a.download = `${linkkey}-${proddate}.${format}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}

async function bulkDownload(format, reports, filename) {
    const request = await fetch(
        `/api/v1/reports/bulk_download`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    reports: reports,
                    format: format
                }
            )
        }
    )

    if (request.status == 200) {
        const blob = await request.blob()
        let fileURL = window.URL.createObjectURL(blob);
        let a  = document.createElement('a');
        a.href = fileURL;
        a.download = `${filename}.${format == 'ascii' ? 'txt' : format}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}

export {
    download,
    bulkDownload
};