import React from 'react';
import { userinfo, login } from '../../hooks/User.js';
import './styles.css';
import '../../components/ReportNavigator/styles.css';


export default class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    async login() {
        this.setState(()=>({loading: true}))
        login();
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="loading login">
                        <img className="icon icon-loading"></img>
                        <p>Redirecting to Hilcorp...</p>
                </div>
            )
        }
        return (
            <div className='login'>
                <div className='login-section'>
                    <h1>Welcome to Hilcorp ONRR report manager</h1>
                    <button onClick={()=>this.login()}>Sign In</button>
                </div>
                
            </div>
        )
    }
}