import { generateHeaders, generateFetchConfig } from './User';
import { getIP } from './env';


const IP = getIP();

async function searchReports(params) {
    const req = await fetch(
        `${IP}/api/v1/reports`,
        {
            method: `POST`,
            headers: await generateHeaders(),
            body: JSON.stringify(params)
        }
    )
    if (req.status == 200) {
        return (await req.json()).reports;
    }
}

async function getReports(filter=null) {
    let query = ``;
    if (filter) {
        query = `?linkkey=${filter.linkkey}&proddate=${filter.proddate}&report_type=${filter.report_type}`
    }
    const req = await fetch(
        `${IP}/api/v1/reports` + query,
        await generateFetchConfig()
    )
    if (req.status == 200) {
        return (await req.json()).report;
    } else {
        return null;
    }
}

async function saveReportStatus(state) {
    const req = await fetch(
        `${IP}/api/v1/reports/status`,
        {
            method: `POST`,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(state)
        }
    )
    if (req.status == 200) {
        return (await req.json()).reports;
    }
}

async function saveReportIssues(issues) {
    const req = await fetch(
        `${IP}/api/v1/issues`,
        {
            method: `POST`,
            headers: {
                "Content-Type": `application/json`
            },
            body: JSON.stringify(issues)
        }
    )
    if (req.status == 200) {
        return (await req.json()).reports;
    }
}

async function getAreas() {
    const req = await fetch(
        `${IP}/api/v1/areas`,
        {
            headers: {
                "Accept": `application/json`
            }
        }
    );
    if (req.status == 200) {
        return (await req.json()).areas;
    }
}

async function getFilters() {
    const req = await fetch(
        `${IP}/api/v1/pad_filters`,
        {
            headers: await generateHeaders()
        }
    );
    if (req.status == 200) {
        return (await req.json()).filters;
    }
}

async function getLeases(padFilter, operator) {
    const req = await fetch(
        `${IP}/api/v1/leases?pad_filter=${padFilter}&operator=${operator}`,
        {
            headers: await generateHeaders()
        }
    );
    if (req.status == 200) {
        return (await req.json()).leases;
    }
}

async function getOperators() {
    const req = await fetch(
        `${IP}/api/v1/operators`,
        await generateFetchConfig()
    );
    if (req.status == 200) {
        return (await req.json()).operators;
    }

    return ["op1"]
}

export {
    searchReports,
    getAreas,
    getFilters,
    getLeases,
    getOperators,
    getReports
}